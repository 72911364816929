import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import type { User as FirebaseUser } from '@firebase/auth';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  IdTokenResult,
  onAuthStateChanged,
  ParsedToken,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth';
import { firebaseApp } from 'src/libs/firebase';
import type { User } from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import {
  setPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail
} from '@firebase/auth';
import { TenantData, tenantApi } from '../../store/tenant-api';

const auth = getAuth(firebaseApp);

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: EnhancedUser | null;
  tenant: TenantData | null;
}

interface EnhancedUser extends User {
  tokenResult?: IdTokenResult;
  groups?: string[];
}

enum ActionType {
  AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED'
}

type AuthStateChangedAction = {
  type: ActionType.AUTH_STATE_CHANGED;
  payload: {
    isAuthenticated: boolean;
    user: EnhancedUser | null;
    tenant: TenantData | null;
  };
};

type Action = AuthStateChangedAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tenant: null
};

const reducer = (state: State, action: Action): State => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user, tenant } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      tenant
    };
  }

  return state;
};

export interface AuthContextType extends State {
  issuer: Issuer.Firebase;
  token?: ParsedToken;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.Firebase,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  // request tenant data which is then provided to firebase
  const [getTenant] = tenantApi.useLazyGetTenantQuery();

  const handleAuthStateChanged = useCallback(
    async (user: FirebaseUser | null, tenant: TenantData) => {
      if (user && user.emailVerified) {
        const tokenResult = await user.getIdTokenResult();

        console.log("User authenticated")

        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              avatar: user.photoURL || undefined,
              email: user.email || 'anika.visser@devias.io',
              name: 'Anika Visser',
              tokenResult: tokenResult,
              groups: tokenResult.claims.groups
            },
            tenant
          }
        });
      } else {
        if (user && !user.emailVerified) {
          await sendEmailVerification(user);
        }
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: false,
            user: null,
            tenant: null
          }
        });
      }
    },
    [dispatch]
  );

  useEffect(
    () => {
      // wait for tenant data to be available
      getTenant()
        .unwrap()
        .then((tenant) => {
          if (tenant) {
            // provide tenant to firebase
            auth.tenantId = tenant?.name;
            // register firebase authentication handler
            onAuthStateChanged(auth, (user) =>
              handleAuthStateChanged(user, tenant)
            );
          } else console.error('Invalid tenant');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const _signInWithEmailAndPassword = useCallback(
    async (email: string, password: string): Promise<void> => {
      await setPersistence(auth, browserLocalPersistence).then(() => {
        return signInWithEmailAndPassword(auth, email, password)
      });
      //await signInWithEmailAndPassword(auth, email, password);
    },
    []
  );

  const signInWithGoogle = useCallback(async (): Promise<void> => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider);
  }, []);

  const resetPassword = useCallback(async (email: string): Promise<void> => {
    await sendPasswordResetEmail(auth, email);
  }, []);

  const _createUserWithEmailAndPassword = useCallback(
    async (email: string, password: string): Promise<void> => {
      await createUserWithEmailAndPassword(auth, email, password)
    },
    []
  );

  const _signOut = useCallback(async (): Promise<void> => {
    await signOut(auth);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.Firebase,
        createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        signInWithGoogle,
        signOut: _signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
