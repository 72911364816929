import { ReactNode, useMemo, useState } from 'react';
import type { AuthContextType } from 'src/contexts/auth/firebase-context';
import { SvgIcon } from '@mui/material';
import { paths } from 'src/paths';
import { Icon } from '@iconify/react';
import { useAuth } from 'src/hooks/use-auth';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const auth = useAuth<AuthContextType>();

  return useMemo(() => {
    const userSections = [
      {
        items: [
          {
            title: 'Aktuelles',
            path: paths.index,
            icon: (
              <SvgIcon fontSize="small">
                <Icon icon="fa:newspaper-o" />
              </SvgIcon>
            )
          },
          {
            title: 'Dokumente',
            path: paths.documents,
            icon: (
              <SvgIcon fontSize="small">
                <Icon icon="fa:folder" />
              </SvgIcon>
            )
          }
        ]
      }
    ];
    const adminSections = [
      {
        subheader: 'Administration',
        items: [
          {
            title: 'Neuer Inhalt',
            path: paths.admin.index,
            icon: (
              <SvgIcon fontSize="small">
                <Icon icon="fa:plus" />
              </SvgIcon>
            )
          },
          {
            title: 'Zielgruppen',
            path: paths.admin.targeting,
            icon: (
              <SvgIcon fontSize="small">
                <Icon icon="fa:users" />
              </SvgIcon>
            )
          }
        ]
      }
    ];
    const profileSections = [
      {
        subheader: 'Mein Profil',
        items: []
      }
    ];

    if (auth.user?.groups?.includes('admin'))
      return [...userSections, ...adminSections, ...profileSections];

    return [...userSections, ...profileSections];
  }, [auth.user?.groups]);
};
