import { PaletteColor } from '@mui/material';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/// Provides information about current tenant
export interface TenantData {
  name: string;
  palette: {
    primaryColor: PaletteColor;
  };
}

export const tenantApi = createApi({
  reducerPath: 'tenantApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/', mode: 'no-cors' }),
  endpoints: (build) => ({
    getTenant: build.query<TenantData | null, void>({
      query: () => ({ url: '/tenant.json' })
    })
  })
});

export const { useGetTenantQuery } = tenantApi;
