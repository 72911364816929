import { lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { IssuerGuard } from 'src/guards/issuer-guard';
import { GuestGuard } from 'src/guards/guest-guard';
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import { Issuer } from 'src/utils/auth';

/*
// Amplify
const AmplifyConfirmRegisterPage = lazy(
  () => import('src/pages/auth/amplify/confirm-register')
);
const AmplifyForgotPasswordPage = lazy(
  () => import('src/pages/auth/amplify/forgot-password')
);
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));
const AmplifyRegisterPage = lazy(
  () => import('src/pages/auth/amplify/register')
);
const AmplifyResetPasswordPage = lazy(
  () => import('src/pages/auth/amplify/reset-password')
);
*/
/*
// Auth0
const Auth0CallbackPage = lazy(() => import('src/pages/auth/auth0/callback'));
const Auth0LoginPage = lazy(() => import('src/pages/auth/auth0/login'));
*/

// Firebase
const FirebaseLoginPage = lazy(() => import('src/pages/auth/firebase/login'));
const FirebaseRegisterPage = lazy(
  () => import('src/pages/auth/firebase/register')
);
const TermsOfServicePage = lazy( () => import('src/pages/auth/firebase/terms-de'))
const PrivacyPolicyPage = lazy( () => import('src/pages/auth/firebase/privacy-de'))

/*
// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
*/

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <IssuerGuard issuer={Issuer.Firebase}>
        <GuestGuard>
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        </GuestGuard>
      </IssuerGuard>
    ),
    children: [
      {
        path: 'login',
        element: <FirebaseLoginPage />
      },
      {
        path: 'register',
        element: <FirebaseRegisterPage />
      },
      {
        path: 'terms',
        element: <TermsOfServicePage />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicyPage />
      }
    ]
  }
];
