import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from 'src/layouts/default';

const IndexPage = lazy(() => import('src/pages/admin/post'));
const TargetingPage = lazy(() => import('src/pages/admin/post'));

export const adminRoutes: RouteObject[] = [
  {
    path: 'admin',
    element: (
      <Layout>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'targeting',
        element: <TargetingPage />
      }
    ]
  }
];
