import { Box } from '@mui/material';
import type { FC } from 'react';

export const Logo: FC = () => {
  // const theme = useTheme();
  // const fillColor = theme.palette.primary.main;

  return (
    <img
      src="/assets/logo.png"
      alt="deinverband"
      height={64}
    />
  );
};
