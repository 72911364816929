import { emptySplitApi as api } from './empty-api';
export const addTagTypes = [
  'Association Resource',
  'Company Resource',
  'Craft Resource',
  'Document Resource',
  'Fruit Resource',
  'Guild Resource',
  'Example Resource',
  'User List Resource',
  'Post Resource',
  'Role Resource',
  'Token Secured Resource',
  'User Resource'
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiAssociation: build.query<
        GetApiAssociationApiResponse,
        GetApiAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/association`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Association Resource']
      }),
      postApiAssociation: build.mutation<
        PostApiAssociationApiResponse,
        PostApiAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/association`,
          method: 'POST',
          body: queryArg.createAssociation
        }),
        invalidatesTags: ['Association Resource']
      }),
      getApiCompanies: build.query<
        GetApiCompaniesApiResponse,
        GetApiCompaniesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Company Resource']
      }),
      getApiCraft: build.query<GetApiCraftApiResponse, GetApiCraftApiArg>({
        query: (queryArg) => ({
          url: `/api/craft`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Craft Resource']
      }),
      postApiCraft: build.mutation<PostApiCraftApiResponse, PostApiCraftApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/craft`,
            method: 'POST',
            body: queryArg.createCraft
          }),
          invalidatesTags: ['Craft Resource']
        }
      ),
      getApiCraftById: build.query<
        GetApiCraftByIdApiResponse,
        GetApiCraftByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/craft/${queryArg.id}` }),
        providesTags: ['Craft Resource']
      }),
      patchApiCraftById: build.mutation<
        PatchApiCraftByIdApiResponse,
        PatchApiCraftByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/craft/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.body
        }),
        invalidatesTags: ['Craft Resource']
      }),
      getApiDocumentCategories: build.query<
        GetApiDocumentCategoriesApiResponse,
        GetApiDocumentCategoriesApiArg
      >({
        query: () => ({ url: `/api/document/categories` }),
        providesTags: ['Document Resource']
      }),
      putApiDocumentFilesById: build.mutation<
        PutApiDocumentFilesByIdApiResponse,
        PutApiDocumentFilesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateDocument
        }),
        invalidatesTags: ['Document Resource']
      }),
      deleteApiDocumentFilesById: build.mutation<
        DeleteApiDocumentFilesByIdApiResponse,
        DeleteApiDocumentFilesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFilesByIdDownload: build.query<
        GetApiDocumentFilesByIdDownloadApiResponse,
        GetApiDocumentFilesByIdDownloadApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/files/${queryArg.id}/download`
        }),
        providesTags: ['Document Resource']
      }),
      getApiDocumentFolders: build.query<
        GetApiDocumentFoldersApiResponse,
        GetApiDocumentFoldersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Document Resource']
      }),
      postApiDocumentFolders: build.mutation<
        PostApiDocumentFoldersApiResponse,
        PostApiDocumentFoldersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders`,
          method: 'POST',
          body: queryArg.createFolder
        }),
        invalidatesTags: ['Document Resource']
      }),
      putApiDocumentFoldersById: build.mutation<
        PutApiDocumentFoldersByIdApiResponse,
        PutApiDocumentFoldersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateFolder
        }),
        invalidatesTags: ['Document Resource']
      }),
      deleteApiDocumentFoldersById: build.mutation<
        DeleteApiDocumentFoldersByIdApiResponse,
        DeleteApiDocumentFoldersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiDocumentFoldersByIdContents: build.query<
        GetApiDocumentFoldersByIdContentsApiResponse,
        GetApiDocumentFoldersByIdContentsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/contents`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Document Resource']
      }),
      postApiDocumentFoldersByIdFiles: build.mutation<
        PostApiDocumentFoldersByIdFilesApiResponse,
        PostApiDocumentFoldersByIdFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/files`,
          method: 'POST',
          body: queryArg.createDocument
        }),
        invalidatesTags: ['Document Resource']
      }),
      postApiDocumentFoldersByIdFolders: build.mutation<
        PostApiDocumentFoldersByIdFoldersApiResponse,
        PostApiDocumentFoldersByIdFoldersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document/folders/${queryArg.id}/folders`,
          method: 'POST',
          body: queryArg.createFolder
        }),
        invalidatesTags: ['Document Resource']
      }),
      getApiFruits: build.query<GetApiFruitsApiResponse, GetApiFruitsApiArg>({
        query: () => ({ url: `/api/fruits` }),
        providesTags: ['Fruit Resource']
      }),
      getApiGuild: build.query<GetApiGuildApiResponse, GetApiGuildApiArg>({
        query: (queryArg) => ({
          url: `/api/guild`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size
          }
        }),
        providesTags: ['Guild Resource']
      }),
      postApiGuild: build.mutation<PostApiGuildApiResponse, PostApiGuildApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/guild`,
            method: 'POST',
            body: queryArg.createGuild
          }),
          invalidatesTags: ['Guild Resource']
        }
      ),
      getApiGuildById: build.query<
        GetApiGuildByIdApiResponse,
        GetApiGuildByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/guild/${queryArg.id}` }),
        providesTags: ['Guild Resource']
      }),
      patchApiGuildById: build.mutation<
        PatchApiGuildByIdApiResponse,
        PatchApiGuildByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/guild/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.body
        }),
        invalidatesTags: ['Guild Resource']
      }),
      getApiHello: build.query<GetApiHelloApiResponse, GetApiHelloApiArg>({
        query: () => ({ url: `/api/hello` }),
        providesTags: ['Example Resource']
      }),
      getApiLists: build.query<GetApiListsApiResponse, GetApiListsApiArg>({
        query: (queryArg) => ({
          url: `/api/lists`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size
          }
        }),
        providesTags: ['User List Resource']
      }),
      postApiLists: build.mutation<PostApiListsApiResponse, PostApiListsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/lists`,
            method: 'POST',
            body: queryArg.createUserList
          }),
          invalidatesTags: ['User List Resource']
        }
      ),
      getApiListsById: build.query<
        GetApiListsByIdApiResponse,
        GetApiListsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/lists/${queryArg.id}` }),
        providesTags: ['User List Resource']
      }),
      putApiListsById: build.mutation<
        PutApiListsByIdApiResponse,
        PutApiListsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.updateUserListInfo
        }),
        invalidatesTags: ['User List Resource']
      }),
      deleteApiListsById: build.mutation<
        DeleteApiListsByIdApiResponse,
        DeleteApiListsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['User List Resource']
      }),
      getApiListsByIdUsers: build.query<
        GetApiListsByIdUsersApiResponse,
        GetApiListsByIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/lists/${queryArg.id}/users`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['User List Resource']
      }),
      getApiPosts: build.query<GetApiPostsApiResponse, GetApiPostsApiArg>({
        query: (queryArg) => ({
          url: `/api/posts`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Post Resource']
      }),
      postApiPosts: build.mutation<PostApiPostsApiResponse, PostApiPostsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/posts`,
            method: 'POST',
            body: queryArg.createPost
          }),
          invalidatesTags: ['Post Resource']
        }
      ),
      getApiPostsDrafts: build.query<
        GetApiPostsDraftsApiResponse,
        GetApiPostsDraftsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/drafts`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['Post Resource']
      }),
      postApiPostsByIdPublish: build.mutation<
        PostApiPostsByIdPublishApiResponse,
        PostApiPostsByIdPublishApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/publish`,
          method: 'POST'
        }),
        invalidatesTags: ['Post Resource']
      }),
      getApiRoles: build.query<GetApiRolesApiResponse, GetApiRolesApiArg>({
        query: (queryArg) => ({
          url: `/api/roles`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size
          }
        }),
        providesTags: ['Role Resource']
      }),
      getApiSecuredAdminOnly: build.query<
        GetApiSecuredAdminOnlyApiResponse,
        GetApiSecuredAdminOnlyApiArg
      >({
        query: () => ({ url: `/api/secured/admin-only` }),
        providesTags: ['Token Secured Resource']
      }),
      getApiSecuredPermitAll: build.query<
        GetApiSecuredPermitAllApiResponse,
        GetApiSecuredPermitAllApiArg
      >({
        query: () => ({ url: `/api/secured/permit-all` }),
        providesTags: ['Token Secured Resource']
      }),
      getApiUsers: build.query<GetApiUsersApiResponse, GetApiUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/users`,
          params: { page: queryArg.page, size: queryArg.size }
        }),
        providesTags: ['User Resource']
      }),
      postApiUsers: build.mutation<PostApiUsersApiResponse, PostApiUsersApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/users`,
            method: 'POST',
            body: queryArg.createUser
          }),
          invalidatesTags: ['User Resource']
        }
      ),
      getApiUsersMe: build.query<GetApiUsersMeApiResponse, GetApiUsersMeApiArg>(
        {
          query: () => ({ url: `/api/users/me` }),
          providesTags: ['User Resource']
        }
      ),
      patchApiUsersMe: build.mutation<
        PatchApiUsersMeApiResponse,
        PatchApiUsersMeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/me`,
          method: 'PATCH',
          body: queryArg.body
        }),
        invalidatesTags: ['User Resource']
      }),
      getApiUsersById: build.query<
        GetApiUsersByIdApiResponse,
        GetApiUsersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}` }),
        providesTags: ['User Resource']
      }),
      patchApiUsersById: build.mutation<
        PatchApiUsersByIdApiResponse,
        PatchApiUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.body
        }),
        invalidatesTags: ['User Resource']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as deinVerbandApi };
export type GetApiAssociationApiResponse =
  /** status 200 OK */ PaginatedResponseAssociation;
export type GetApiAssociationApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiAssociationApiResponse = unknown;
export type PostApiAssociationApiArg = {
  createAssociation: CreateAssociation;
};
export type GetApiCompaniesApiResponse =
  /** status 200 OK */ PaginatedResponseCompany;
export type GetApiCompaniesApiArg = {
  page?: number | null;
  size?: number | null;
};
export type GetApiCraftApiResponse =
  /** status 200 OK */ PaginatedResponseCraft;
export type GetApiCraftApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiCraftApiResponse = unknown;
export type PostApiCraftApiArg = {
  createCraft: CreateCraft;
};
export type GetApiCraftByIdApiResponse = /** status 200 OK */ Craft1;
export type GetApiCraftByIdApiArg = {
  id: number;
};
export type PatchApiCraftByIdApiResponse = unknown;
export type PatchApiCraftByIdApiArg = {
  id: number;
  body: Blob;
};
export type GetApiDocumentCategoriesApiResponse =
  /** status 200 OK */ DocumentCategory[];
export type GetApiDocumentCategoriesApiArg = void;
export type PutApiDocumentFilesByIdApiResponse = unknown;
export type PutApiDocumentFilesByIdApiArg = {
  id: number;
  updateDocument: UpdateDocument;
};
export type DeleteApiDocumentFilesByIdApiResponse = unknown;
export type DeleteApiDocumentFilesByIdApiArg = {
  id: number;
};
export type GetApiDocumentFilesByIdDownloadApiResponse = unknown;
export type GetApiDocumentFilesByIdDownloadApiArg = {
  id: number;
};
export type GetApiDocumentFoldersApiResponse =
  /** status 200 OK */ PaginatedResponseDocumentItem;
export type GetApiDocumentFoldersApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiDocumentFoldersApiResponse = unknown;
export type PostApiDocumentFoldersApiArg = {
  createFolder: CreateFolder;
};
export type PutApiDocumentFoldersByIdApiResponse = unknown;
export type PutApiDocumentFoldersByIdApiArg = {
  id: number;
  updateFolder: UpdateFolder;
};
export type DeleteApiDocumentFoldersByIdApiResponse = unknown;
export type DeleteApiDocumentFoldersByIdApiArg = {
  id: number;
};
export type GetApiDocumentFoldersByIdContentsApiResponse =
  /** status 200 OK */ PaginatedResponseDocumentItem;
export type GetApiDocumentFoldersByIdContentsApiArg = {
  id: number;
  page?: number | null;
  size?: number | null;
};
export type PostApiDocumentFoldersByIdFilesApiResponse =
  /** status 200 OK */ CreateDocumentResponse;
export type PostApiDocumentFoldersByIdFilesApiArg = {
  id: number;
  createDocument: CreateDocument;
};
export type PostApiDocumentFoldersByIdFoldersApiResponse = unknown;
export type PostApiDocumentFoldersByIdFoldersApiArg = {
  id: number;
  createFolder: CreateFolder;
};
export type GetApiFruitsApiResponse = /** status 200 OK */ Fruit[];
export type GetApiFruitsApiArg = void;
export type GetApiGuildApiResponse =
  /** status 200 OK */ PaginatedResponseGuild;
export type GetApiGuildApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
};
export type PostApiGuildApiResponse = unknown;
export type PostApiGuildApiArg = {
  createGuild: CreateGuild;
};
export type GetApiGuildByIdApiResponse = /** status 200 OK */ Guild;
export type GetApiGuildByIdApiArg = {
  id: number;
};
export type PatchApiGuildByIdApiResponse = /** status 200 OK */ Guild;
export type PatchApiGuildByIdApiArg = {
  id: number;
  body: Blob;
};
export type GetApiHelloApiResponse = /** status 200 OK */ Hello;
export type GetApiHelloApiArg = void;
export type GetApiListsApiResponse =
  /** status 200 OK */ PaginatedResponseUserListInfo;
export type GetApiListsApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
};
export type PostApiListsApiResponse = unknown;
export type PostApiListsApiArg = {
  createUserList: CreateUserList;
};
export type GetApiListsByIdApiResponse = /** status 200 OK */ UserListInfo;
export type GetApiListsByIdApiArg = {
  id: number;
};
export type PutApiListsByIdApiResponse = unknown;
export type PutApiListsByIdApiArg = {
  id: number;
  updateUserListInfo: UpdateUserListInfo;
};
export type DeleteApiListsByIdApiResponse = unknown;
export type DeleteApiListsByIdApiArg = {
  id: number;
};
export type GetApiListsByIdUsersApiResponse =
  /** status 200 OK */ PaginatedResponseEmailUser;
export type GetApiListsByIdUsersApiArg = {
  id: number;
  page?: number | null;
  size?: number | null;
};
export type GetApiPostsApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiPostsApiResponse = unknown;
export type PostApiPostsApiArg = {
  createPost: CreatePost;
};
export type GetApiPostsDraftsApiResponse =
  /** status 200 OK */ PaginatedResponsePostSummary;
export type GetApiPostsDraftsApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiPostsByIdPublishApiResponse = unknown;
export type PostApiPostsByIdPublishApiArg = {
  id: number;
};
export type GetApiRolesApiResponse = /** status 200 OK */ PaginatedResponseRole;
export type GetApiRolesApiArg = {
  page?: number | null;
  search?: string | null;
  size?: number | null;
};
export type GetApiSecuredAdminOnlyApiResponse = unknown;
export type GetApiSecuredAdminOnlyApiArg = void;
export type GetApiSecuredPermitAllApiResponse = unknown;
export type GetApiSecuredPermitAllApiArg = void;
export type GetApiUsersApiResponse =
  /** status 200 OK */ PaginatedResponseUserSummary;
export type GetApiUsersApiArg = {
  page?: number | null;
  size?: number | null;
};
export type PostApiUsersApiResponse = unknown;
export type PostApiUsersApiArg = {
  createUser: CreateUser;
};
export type GetApiUsersMeApiResponse = /** status 200 OK */ UserDetails;
export type GetApiUsersMeApiArg = void;
export type PatchApiUsersMeApiResponse = unknown;
export type PatchApiUsersMeApiArg = {
  body: Blob;
};
export type GetApiUsersByIdApiResponse = /** status 200 OK */ UserSummary;
export type GetApiUsersByIdApiArg = {
  id: number;
};
export type PatchApiUsersByIdApiResponse = unknown;
export type PatchApiUsersByIdApiArg = {
  id: number;
  body: Blob;
};
export type Association = {
  id?: number;
  tenantId: string;
  name: string;
  domain: string;
  address: string;
  zip: string;
  city: string;
};
export type PaginationMetadata = {
  page?: number;
  size?: number;
  totalItems?: number;
  totalPages?: number;
  next?: string | null;
  previous?: string | null;
};
export type PaginatedResponseAssociation = {
  data: Association[];
  meta: PaginationMetadata;
};
export type CreateAssociation = {
  name: string;
  address: string;
  zip: string;
  city: string;
  domain: string;
  adminEmail: string;
  adminPassword: string;
};
export type Company = {
  id?: number;
  name: string;
  address: string;
  city: string;
  zip: string;
  phone?: string | null;
  fax?: string | null;
  email?: string | null;
  website?: string | null;
  logo?: string | null;
};
export type PaginatedResponseCompany = {
  data: Company[];
  meta: PaginationMetadata;
};
export type Craft1 = {
  id?: number;
  name: string;
  description?: string | null;
  logo?: string | null;
  memberIcon?: string | null;
  headIcon?: string | null;
  viceHeadIcon?: string | null;
  apprenticeHeadIcon?: string | null;
};
export type PaginatedResponseCraft = {
  data: Craft1[];
  meta: PaginationMetadata;
};
export type CreateCraft = {
  name: string;
  description?: string | null;
  logo?: string | null;
  memberIcon?: string | null;
  headIcon?: string | null;
  viceHeadIcon?: string | null;
  apprenticeHeadIcon?: string | null;
};
export type DocumentCategory = {
  id?: number;
  name: string;
};
export type UpdateDocument = {
  name?: string | null;
  tags?: string[] | null;
};
export type LocalDateTime = string;
export type DocumentItem = {
  id?: number;
  folderId?: number | null;
  createdAt?: LocalDateTime | null;
  type: string;
  name: string;
  icon?: string | null;
  categoryId?: number | null;
};
export type PaginatedResponseDocumentItem = {
  data: DocumentItem[];
  meta: PaginationMetadata;
};
export type CreateFolder = {
  name: string;
  categoryId?: number;
  icon?: string | null;
  tags: string[];
};
export type UpdateFolder = {
  name?: string | null;
  icon?: string | null;
  tags?: string[] | null;
};
export type CreateDocumentResponse = {
  id?: number;
  uploadUrl: string;
};
export type CreateDocument = {
  name: string;
  tags: string[];
};
export type Fruit = {
  id?: number | null;
  name: string;
};
export type Guild = {
  id?: number;
  name: string;
  logo?: string | null;
  parentGuildId?: number | null;
  crafts: Craft1[];
};
export type PaginatedResponseGuild = {
  data: Guild[];
  meta: PaginationMetadata;
};
export type CreateGuild = {
  name: string;
  logo?: string | null;
  parentGuildId?: number | null;
  craftIds: number[];
  tenantId?: string | null;
};
export type Hello = {
  message: string;
};
export type EmailUser = {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
};
export type UserListInfo = {
  id?: number;
  name: string;
  description?: string | null;
  createdAt: LocalDateTime;
  creator: EmailUser;
};
export type PaginatedResponseUserListInfo = {
  data: UserListInfo[];
  meta: PaginationMetadata;
};
export type UserListFilter = {
  guildId?: number | null;
  roleId?: number | null;
};
export type CreateUserList = {
  name: string;
  description?: string | null;
  filters: UserListFilter[];
  userIds: number[];
};
export type UpdateUserListInfo = {
  name?: string | null;
  description?: string | null;
};
export type PaginatedResponseEmailUser = {
  data: EmailUser[];
  meta: PaginationMetadata;
};
export type PostSummary = {
  id?: number;
  title: string;
  body: string;
  author: EmailUser;
  category: DocumentCategory;
  createdAt: LocalDateTime;
  numComments?: number;
  numViews?: number;
  numVotes?: number;
  hasAttachment?: boolean;
  hasEvent?: boolean;
  publishedAt: LocalDateTime;
};
export type PaginatedResponsePostSummary = {
  data: PostSummary[];
  meta: PaginationMetadata;
};
export type Location = {
  streetAddress: string;
  locality: string;
  postalCode: string;
  lat?: number | null;
  lng?: number | null;
};
export type CreateEvent = {
  name: string;
  description?: string | null;
  eventTypeId?: number;
  onlineMeetingUrl?: string | null;
  startAt: LocalDateTime;
  endAt?: LocalDateTime | null;
  location?: Location | null;
};
export type Recipients = {
  userListId?: number | null;
  companyIds: number[];
  guildIds: number[];
};
export type CreatePost = {
  title: string;
  body: string;
  categoryId?: number | null;
  event?: CreateEvent | null;
  attachments: number[];
  recipients: Recipients;
};
export type Role = {
  id?: number;
  name: string;
};
export type PaginatedResponseRole = {
  data: Role[];
  meta: PaginationMetadata;
};
export type UserSummary = {
  companyId?: number;
  companyName1?: string | null;
  companyName2?: string | null;
  companyName3?: string | null;
  userId?: number;
  userFirstName?: string | null;
  userLastName?: string | null;
  userGender?: number;
  userEmail?: string | null;
  userInvited?: number;
  companyTypeId?: number;
  companyTypeName?: string | null;
  companyTypeUsename?: boolean;
  userAdmin?: number;
  userLoginsCount?: number;
  userDevicesCount?: number;
};
export type PaginatedResponseUserSummary = {
  data: UserSummary[];
  meta: PaginationMetadata;
};
export type GuildReadAccess = {
  guildId?: number;
  roleId?: number;
};
export type GuildWriteAccess = {
  guildId?: number;
};
export type CreateUser = {
  companyId?: number;
  gender?: number;
  firstName: string;
  lastName: string;
  mail: string;
  password?: string | null;
  isAdmin?: boolean;
  readAccess: GuildReadAccess[];
  writeAccess: GuildWriteAccess[];
  admin?: boolean;
};
export type UserDetails = {
  id?: number;
  gender?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  companyId?: number;
  companyLogo?: string | null;
  companyName1?: string | null;
  companyName2?: string | null;
  companyName3?: string | null;
  passwordInit?: string | null;
  superAdmin?: number;
  street?: string | null;
  zip?: string | null;
  location?: string | null;
  companyEmail?: string | null;
  phone1?: string | null;
  phone2?: string | null;
  mobile?: string | null;
  fax?: string | null;
  web?: string | null;
  admin?: number;
  associationId?: number;
  associationName?: string | null;
  settingsMode?: number;
  author?: number;
};
export const {
  useGetApiAssociationQuery,
  usePostApiAssociationMutation,
  useGetApiCompaniesQuery,
  useGetApiCraftQuery,
  usePostApiCraftMutation,
  useGetApiCraftByIdQuery,
  usePatchApiCraftByIdMutation,
  useGetApiDocumentCategoriesQuery,
  usePutApiDocumentFilesByIdMutation,
  useDeleteApiDocumentFilesByIdMutation,
  useGetApiDocumentFilesByIdDownloadQuery,
  useGetApiDocumentFoldersQuery,
  usePostApiDocumentFoldersMutation,
  usePutApiDocumentFoldersByIdMutation,
  useDeleteApiDocumentFoldersByIdMutation,
  useGetApiDocumentFoldersByIdContentsQuery,
  usePostApiDocumentFoldersByIdFilesMutation,
  usePostApiDocumentFoldersByIdFoldersMutation,
  useGetApiFruitsQuery,
  useGetApiGuildQuery,
  usePostApiGuildMutation,
  useGetApiGuildByIdQuery,
  usePatchApiGuildByIdMutation,
  useGetApiHelloQuery,
  useGetApiListsQuery,
  usePostApiListsMutation,
  useGetApiListsByIdQuery,
  usePutApiListsByIdMutation,
  useDeleteApiListsByIdMutation,
  useGetApiListsByIdUsersQuery,
  useGetApiPostsQuery,
  usePostApiPostsMutation,
  useGetApiPostsDraftsQuery,
  usePostApiPostsByIdPublishMutation,
  useGetApiRolesQuery,
  useGetApiSecuredAdminOnlyQuery,
  useGetApiSecuredPermitAllQuery,
  useGetApiUsersQuery,
  usePostApiUsersMutation,
  useGetApiUsersMeQuery,
  usePatchApiUsersMeMutation,
  useGetApiUsersByIdQuery,
  usePatchApiUsersByIdMutation
} = injectedRtkApi;
