import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Layout } from 'src/layouts/default';

const NewsPage = lazy(() => import('src/pages/user/news'));
const DocumentsPage = lazy(() => import('src/pages/user/documents'));
const AccountPage = lazy(() => import('src/pages/user/account'));

export const userRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <Layout>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <NewsPage />
      },
      {
        path: 'documents',
        element: <DocumentsPage />,
        children: [
          {
            path: ':id',
            element: <DocumentsPage />
          }
        ]
      },
      {
        path: 'profile',
        element: <AccountPage />
      }
    ]
  }
];
