import type { PaletteColor, ThemeOptions } from '@mui/material';
import type { Contrast } from '../index';
import { createComponents } from './create-components';
import { createPalette } from './create-palette';
import { createShadows } from './create-shadows';

interface Config {
  primaryColor?: PaletteColor;
  contrast?: Contrast;
}

export const createOptions = (config: Config): ThemeOptions => {
  const { primaryColor, contrast } = config;
  const palette = createPalette({ primaryColor, contrast });
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows
  };
};
