export const paths = {
  index: '/',
  documents: '/documents',
  profile: '/profile',
  admin: {
    index: '/admin',
    targeting: '/admin/targeting'
  },
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/auth/jwt/login',
      register: '/auth/jwt/register'
    },
    firebase: {
      login: '/auth/login',
      register: '/auth/register',
      terms: '/auth/terms',
      privacy: '/auth/privacy'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  401: '/401',
  404: '/404',
  500: '/500'
};
